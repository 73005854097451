import React from 'react'
import { BsBoxArrowInUpRight } from 'react-icons/bs'
import { dateFormaterWithTime } from '../../../../../helpers/formatters'
import RichTextDisplay from '../../../../../components/textEditor/RichTextDisplay'
import { classNames } from '../../../../../helpers/classNames'
import useNav from '../../../../../hooks/useNav'

const ActivityCard = ({ item, onShowDetails }) => {
    const nav = useNav()
    const activityColors = {
        'Signup Complete': 'text-green-600 bg-green-100 ',
        'Payment Failed': 'text-red-600 bg-red-100 ',
    }
    const thisColor = activityColors[item.type] || 'text-gray-800 bg-gray-100'

    // Function to handle card click to show details
    const handleCardClick = () => {
        onShowDetails(item);
    };

    return (
        <div
            onClick={handleCardClick}
            className="bg-white rounded-lg border border-gray-200 p-3.5 hover:shadow-md transition-shadow group cursor-pointer mb-3"
        >
            <div className="flex flex-col">
                {/* Top section with user and household info */}
                <div className="flex justify-start mb-2 space-x-2">
                    <div className="flex-1">
                        <div className="font-medium text-gray-800">{item.user_name}</div>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                nav(`/crm/households/${item.household_id}`);
                            }}
                            className='flex items-center text-sm hover:underline group-hover:text-blue-600 transition-colors'
                        >
                            {item.household_name}
                            <BsBoxArrowInUpRight className="ml-1 h-3 w-3 flex-shrink-0 opacity-70 group-hover:opacity-100" />
                        </button>
                    </div>

                    <div className="text-sm text-gray-500 whitespace-nowrap">
                        {dateFormaterWithTime(new Date(item.created_at))}
                    </div>
                </div>

                {/* Activity type badge */}
                <div className="mb-2.5">
                    <span className={classNames(thisColor, 'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium')}>
                        {item.type}
                    </span>
                </div>

                {/* Truncated details */}
                <div className="text-sm text-gray-700 line-clamp-2 overflow-hidden">
                    {item.content ?
                        <div className="truncate-rich-text">
                            <RichTextDisplay content={item.content} thisClassName={'notes'} />
                        </div>
                        :
                        <div className="truncate">{item.details}</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ActivityCard